/**
 * Because the resources come with CORS headers preventing us from doing what we want,
 * we fetch them through our Nuxt proxy so browsers can trust the resource.
 * => we use local urls, set (routeRules in nuxt.config.ts) to redirect to the external resource.
 */
const accessibleImgHostUrls = {
  '/yt-images/': /https:\/\/i\.ytimg\.com\/vi\/(.+)/,
  '/sp-images/': /https:\/\/i\.scdn\.co\/image\/(.+)/,
  '/sc-images/': /https:\/\/i1\.sndcdn\.com\/(.+)/,
  '/am-images/': /https:\/\/assets\.audiomack\.com\/(.+)/,
} as const

/**
 * Using nuxt3's proxy we can remove the cross origin from medias pulled on our providers. Reffer to the nuxt.config.ts file `routeRules` key.
 *
 * @param trackPictureUrl - the target picture URL from YouTube Souncloud Audiomack or Spotify.
 * @returns un-CORS'ed path
 */
export function removeCorsFromCoverArt(trackPictureUrl: string) {
  const foundHost = Object.entries(accessibleImgHostUrls).find(
    ([_, extUrlRegexp]) => extUrlRegexp.test(trackPictureUrl),
  )
  if (!foundHost) return
  const [internalUrl, extUrlRegexp] = foundHost
  const match = trackPictureUrl.match(extUrlRegexp)
  if (!match?.[1]) return
  const resourceName = match[1]
  return `${internalUrl}${resourceName}`
}

/** (Re)load provided image and proceed to call callback on ready */
export function loadCorsImage(
  image: Ref<InstanceType<typeof Image> | null>,
  src: string,
  onload?: () => void,
) {
  image.value = new Image()
  image.value.onload = () => {
    if (onload) onload()
  }
  const unCORSedUrl = removeCorsFromCoverArt(src)

  if (!unCORSedUrl) return
  image.value.src = unCORSedUrl
}
