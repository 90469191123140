import { provideAspectRatioUtils } from '~/helpers/AspectRatioCanvas'

import type { NormalizeFunction } from '~/helpers/AspectRatioCanvas'

/**
 * Will return the text truncated with `...` that fits in the provided maxWidth parament
 *
 * @param text - the text to shorten.
 * @param maxWidth - the maximum width in px the text can span.
 * @param ctx - the canvas context.
 */
export function truncateText(
  text: string,
  maxWidth: number,
  ctx: CanvasRenderingContext2D,
) {
  const width = ctx.measureText(text).width
  const ellipsis = width > maxWidth ? '...' : ''
  const len = text.length
  for (let i = len; i > 0; i--) {
    const str = text.substring(0, i) + ellipsis
    if (ctx.measureText(str).width <= maxWidth) return str
  }
  return ''
}

/**
 * This will return measurements for texts that will be rendered by {@link writeTextCentered}
 *
 * @param ctx - the canvas context
 * @param canvasElement - the canvas element
 * @param text - the text to measure
 * @param x - x position
 * @param _y - y position
 * @param font - desired font
 * @param style - desired style
 * @param offsetX - x offset
 * @param _offsetY - y offset
 */
export function getCenteredTextMeasurements(
  ctx: CanvasRenderingContext2D,
  canvasElement: HTMLCanvasElement,
  text: string,
  x: number,
  _y: number,
  font: string,
  style: string | CanvasGradient | CanvasPattern,
  normalizeX: NormalizeFunction,
  offsetX = 0,
  _offsetY = 0,
) {
  const maxWidth = normalizeX(canvasElement.width) - (x + Math.abs(offsetX)) * 2

  ctx.textAlign = 'center'
  ctx.font = font
  ctx.fillStyle = style

  text = truncateText(text.replace(/\s+/g, ' '), maxWidth, ctx)
  return { measurements: ctx.measureText(text), maxWidth, text }
}

/**
 * Truncate and write text in space based of the x position for it's padding.
 *
 * @param ctx - the canvas context
 * @param canvasElement - the canvas element
 * @param text - the text to measure
 * @param x - x position
 * @param _y - y position
 * @param font - desired font
 * @param style - desired style
 * @param normalizeX - function provided by `provideAspectRatioUtils` that normalizes the X values based on the current sizes
 * @param offsetX - x offset
 * @param offsetY - y offset
 */
export function writeTextCentered(
  ctx: CanvasRenderingContext2D,
  canvasElement: HTMLCanvasElement,
  importedText: string,
  x: number,
  y: number,
  font: string,
  style: string | CanvasGradient | CanvasPattern,
  normalizeX: NormalizeFunction,
  offsetX = 0,
  offsetY = 0,
) {
  const { measurements, maxWidth, text } = getCenteredTextMeasurements(
    ctx,
    canvasElement,
    importedText,
    x,
    y,
    font,
    style,
    normalizeX,
    offsetX,
    offsetY,
  )

  ctx.fillText(
    text,
    offsetX + normalizeX(canvasElement.width) / 2,
    offsetY + y + measurements.actualBoundingBoxAscent,
    maxWidth,
  )

  return measurements
}

/**
 * Prepare a clip context, good to note, that this function doesn't call clip!
 * - {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/clip|clip documentation}
 * - {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/save|save documentation}
 * @see {@link https://stackoverflow.com/a/19593950}
 */
export function roundedClip(
  ctx: CanvasRenderingContext2D,
  {
    x,
    y,
    width,
    height,
    radius,
  }: { x: number; y: number; width: number; height: number; radius: number },
) {
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.lineTo(x + width - radius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
  ctx.lineTo(x + width, y + height - radius)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  ctx.lineTo(x + radius, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
  ctx.lineTo(x, y + radius)
  ctx.quadraticCurveTo(x, y, x + radius, y)
  ctx.closePath()
}
