<script lang="ts" setup generic="T extends string[]">
type Props = {
  /** HEX codes for colors */
  availableColors: T
}

const props = defineProps<Props>()
const selectedColor = defineModel<T[number]>('selectedColor')
const selectedColorIndex = computed<number | -1>(() => {
  if (!selectedColor.value) return -1
  return props.availableColors.indexOf(selectedColor.value)
})
const colorsNeedingSecondOutlineColor = ['#000']

selectedColor.value = props.availableColors[0] || ''
</script>

<template>
  <ul
    class="tw-flex tw-justify-between tw-gap-md sm:tw-flex-wrap sm:tw-justify-start sm:tw-gap-2xl"
  >
    <li
      v-for="(pickableColor, index) in availableColors"
      :key="index"
      class="tw-relative tw-aspect-square tw-h-3xl tw-w-3xl tw-cursor-pointer tw-rounded-full tw-transition-all 375:tw-h-4xl 375:tw-w-4xl"
      :class="{
        'tw-border tw-border-discrete3': index !== selectedColorIndex,
        // offset -1 and width +1 so as to leave no ugly space between the element and the outline
        'tw-outline tw-outline-[5px] -tw-outline-offset-1 !tw-outline-neutral-700':
          index === selectedColorIndex &&
          colorsNeedingSecondOutlineColor.includes(pickableColor),
        'tw-outline tw-outline-4 !tw-outline-pride-black':
          index === selectedColorIndex &&
          !colorsNeedingSecondOutlineColor.includes(pickableColor),
      }"
      :style="{ backgroundColor: pickableColor }"
    >
      <button
        class="tw-h-full tw-w-full"
        type="button"
        @click="selectedColor = pickableColor"
      />
    </li>
  </ul>
</template>
