export type AspectRatioDescriptor = {
  /** Kara's design is presented in a different scale input the width of the design canvas */
  designX: number
  /** Kara's design is presented in a different scale input the height of the design canvas */
  designY: number
  /** Desired width for the target media */
  targetX: number
  /** Desired height for the target media */
  targetY: number
}

export function provideAspectRatioUtils(
  { designX, designY, targetX, targetY }: AspectRatioDescriptor = {
    designX: 375,
    designY: 660,
    targetX: 1080,
    targetY: 1920,
  },
) {
  return {
    /**
     * Used to normalized X bound values from non scaled canvas element.
     *
     * @param value - input un-nomalized canvas element value.
     * @returns the normalized value.
     */
    normalizeX(value: number) {
      return (value / targetX) * designX
    },

    /**
     * Used to normalized Y bound values from non scaled canvas element.
     *
     * @param value - input un-nomalized canvas element value.
     * @returns the normalized value.
     */
    normalizeY(value: number) {
      return (value / targetY) * designY
    },
  }
}

export type NormalizeFunction = ReturnType<
  typeof provideAspectRatioUtils
>['normalizeX']
