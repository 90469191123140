<script lang="ts" setup>
import { useElementSize } from '@vueuse/core'

import { provideAspectRatioUtils } from '~/helpers/AspectRatioCanvas'

import type { AspectRatioDescriptor } from '~/helpers/AspectRatioCanvas'

type Props = Partial<AspectRatioDescriptor>

type Emits = {
  ready: []
}

const props = withDefaults(defineProps<Props>(), {
  designX: 375,
  designY: 660,
  targetX: 1080,
  targetY: 1920,
})

const { normalizeX, normalizeY } = provideAspectRatioUtils(props)

const emit = defineEmits<Emits>()

/**
 * Apply scale transform based off these factors and considering the 9/16 ratio is respected.
 * - The design mockup sizes.
 * - Ideal instagram render size (1080/1920).
 * - {@link parentContainerSize}
 */
const scaleBasedOffParentSize = computed(() => {
  return `transform: scale(${parentContainerSize.width.value / props.targetX}, ${
    parentContainerSize.height.value / props.targetY
  })`
})

/** The main parent, used to computed scale factors */
const parentContainerSize = useElementSize(useTemplateRef('parent'))
/** The main canvas element, this is not scaled in context and using any pixel value should be wrapped with {@link normalizeX} or {@link normalizeY} */
const canvas = useTemplateRef('canvas')
/** The derived context from {@link canvas} */
const canvasContext = shallowRef<null | CanvasRenderingContext2D>(null)

onMounted(() => {
  if (!canvas.value) return

  canvasContext.value = canvas.value.getContext('2d')

  if (!canvasContext.value) return

  canvasContext.value.scale(
    props.targetX / props.designX,
    props.targetY / props.designY,
  )

  emit('ready')
})

defineExpose({
  normalizeX,
  normalizeY,
  canvas,
  canvasContext,
} as const)
</script>
<template>
  <div ref="parent" class="tw-relative tw-h-full tw-w-full">
    <canvas
      ref="canvas"
      width="1080"
      height="1920"
      :style="scaleBasedOffParentSize"
    />
  </div>
</template>

<style lang="scss" scoped>
canvas {
  transform-origin: top left;
}
</style>
